<template>
<!-- 提交订单（选择支付方式） -->
  <div class="content-page">
    <b-card>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/webstore">Webstore</router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/webstorecart">Cart</router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">Submit Order</li>
        </ol>
      </nav>
    </b-card>
    <div class="row">
      <b-card class="col-12 col-md-8 offset-md-2 w-75 mt-3 p-3">
        <h4>Payment method</h4>
        <div class="row">
          <div class="col"><input type="radio" checked /> Top-up balance</div>
          <div class="col text-right">
            <span>$1592.50</span>
          </div>
        </div>
        <div><input type="radio" /> Credit Card</div>
      </b-card>
      <b-card class="col-12 col-md-8 offset-md-2 w-75 mt-3 p-3">
        <h4>Payment Details</h4>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">Card Number</p>
          <div class="col-12 col-md-10 p-0">
            <input class="int" type="text" />
          </div>
        </div>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">Name on the Card</p>
          <div class="col-12 col-md-10">
            <div class="row">
              <input class="int col" placeholder="First Name" type="text" />
              <input class="int ml-2 col" placeholder="Last Name" type="text" />
            </div>
          </div>
        </div>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">Country</p>
          <div class="col-12 col-md-10 p-0">
            <input class="int" type="text" />
          </div>
        </div>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">Address</p>
          <div class="col-12 col-md-10 p-0">
            <input class="int" type="text" />
          </div>
        </div>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">City</p>
          <div class="col-12 col-md-10 p-0">
            <input class="int" type="text" />
          </div>
        </div>
        <div class="row p-2">
          <p class="m-0 col-md-2 col-12">Region & Postal Code</p>
          <div class="col-12 col-md-10">
            <div class="row">
              <input
                class="int col"
                placeholder="State, Province or District"
                type="text"
              />
              <input
                class="int ml-2 col"
                placeholder="Postal Code"
                type="text"
              />
            </div>
          </div>
        </div>
      </b-card>

      <b-card class="col-12 col-md-8 offset-md-2 w-75 mt-3 mb-3 p-3">
        <div class="row">
          <div class="col v-center">
            <p class="m-0 price">
              Accounts payable: <strong style="color: #333">$608.50</strong>
            </p>
          </div>
          <div class="col text-right">
            <button class="btn">Pay Now</button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
  color: #333;
  font-family: Poppins Medium, Poppins Medium-Medium;
  .breadcrumb {
    background: #fff;
    margin: 0;
  }
}
.btn {
  width: 120px;
  height: 45px;
  color: #ffffff;
  background: #50b5ff;
  border-radius: 5px;
}
.v-center {
  margin: auto 0;
}
.int {
  width: 100%;
  height: 45px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
</style>